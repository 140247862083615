import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {
  ConductedSurvey, ConductedSurveyDeleteDto, ConductedSurveyGenericDto,
  ConductedSurveyInclusiveDto, ConductedSurveyInclusiveListDto, ConductedSurveyScorecardChartV3Dto,
  ConductedSurveyScorecardDto, ConductedSurveyScorecardExportV3Dto,
  ConductedSurveyScorecardExportV4Dto, ConductedSurveyScorecardV2Dto,
  SurveyType
} from './conducted-survey.model';
import {SessionQuery} from '../../../../authentication/session/session.query';
import {ConductedSurveyReturnDto} from 'app/modules/admin/components/survey-management/store/survey.model';

@Injectable({providedIn: 'root'})
export class ConductedSurveyService {
  constructor(private http: HttpClient,
              private sessionQuery: SessionQuery) {
  }

  // used by hub user
  getAll(): Observable<ConductedSurveyInclusiveDto[]> {
    const url = `${environment.apiUrl}/conducted-survey/`;
    return this.http.get<ConductedSurveyInclusiveDto[]>(url);
  }

  // used by hub user
  getAllList(): Observable<ConductedSurveyInclusiveListDto[]> {
    const url = `${environment.apiUrl}/conducted-survey/list`;
    return this.http.get<ConductedSurveyInclusiveListDto[]>(url);
  }

  // used by organisation user
  getAllByOrganisationUser(): Observable<ConductedSurveyInclusiveListDto[]> {
    const organisationId = this.sessionQuery.getUser().organisationId;
    const url = `${environment.apiUrl}/conducted-survey/by-organisation-user?organisationId=${organisationId}`;
    return this.http.get<ConductedSurveyInclusiveListDto[]>(url);
  }

  // used by organisation user
  getByOrganisationUserPerSurvey(surveyId: number): Observable<ConductedSurveyInclusiveDto[]> {
    const organisationId = this.sessionQuery.getUser().organisationId;
    const url = `${environment.apiUrl}/conducted-survey/by-organisation-user/by-survey?surveyId=${surveyId}&organisationId=${organisationId}`;
    return this.http.get<ConductedSurveyInclusiveDto[]>(url);
  }

  // used by facilitator user
  getAllByFacilitatorUser(): Observable<ConductedSurveyInclusiveDto[]> {
    const url = `${environment.apiUrl}/conducted-survey/by-facilitator-user`;
    return this.http.get<ConductedSurveyInclusiveDto[]>(url);
  }

  // used by facilitator user
  getListByFacilitatorUser(): Observable<ConductedSurveyInclusiveListDto[]> {
    const url = `${environment.apiUrl}/conducted-survey/list/by-facilitator-user`;
    return this.http.get<ConductedSurveyInclusiveListDto[]>(url);
  }

  getAllByClientId(clientId: number) {
    const url = `${environment.apiUrl}/conducted-survey/by-household?clientId=${clientId}`;
    return this.http.get<ConductedSurvey[]>(url);
  }

  getAllByClientIdAndType(clientId: number, type: 'GREENLIGHT' | 'GENERIC') {
    const url = `${environment.apiUrl}/conducted-survey/by-household-and-survey-type?clientId=${clientId}&surveyType=${type}`;
    return this.http.get<ConductedSurvey[]>(url);
  }

  getAllInclusiveByClientIdAndType(clientId: number, type: 'GREENLIGHT' | 'GENERIC') {
    const url = `${environment.apiUrl}/conducted-survey/inclusive/by-household-and-survey-type?clientId=${clientId}&surveyType=${type}`;
    return this.http.get<ConductedSurveyInclusiveListDto[]>(url);
  }

  getAllByFacilitatorId(facilitatorId: number) {
    const url = `${environment.apiUrl}/conducted-survey/by-facilitator?facilitatorId=${facilitatorId}`;
    return this.http.get<ConductedSurvey[]>(url);
  }

  getAllByOrganisationId(organisationId: number) {
    const url = `${environment.apiUrl}/conducted-survey/by-organisation-user?organisationId=${organisationId}`;
    return this.http.get<ConductedSurveyInclusiveListDto[]>(url);
  }

  getByHouseholdAndSurveyType(clientId: number, surveyType: SurveyType): Observable<ConductedSurvey[]> {
    let url = `${environment.apiUrl}/conducted-survey/by-household-and-survey-type`;
    url = `${url}?clientId=${clientId}&surveyType=${surveyType}`;
    return this.http.get<ConductedSurvey[]>(url);
  }

  updateNarrative(conductedSurveyId: number, narrative: string): Observable<ConductedSurvey> {
    const url = `${environment.apiUrl}/conducted-survey/${conductedSurveyId}/narrative`;
    return this.http.put<ConductedSurvey>(url, {narrative});
  }

  update(conductedSurveyId: number, conductedSurvey: ConductedSurvey): Observable<ConductedSurvey> {
    const url = `${environment.apiUrl}/conducted-survey/${conductedSurveyId}`;
    return this.http.put<ConductedSurvey>(url, conductedSurvey);
  }

  createSurveyLegacy(surveyId: number,
                     // householdId: number,
                     // organisationId: number,
                     // facilitatorId: number,
                     // timeline: TimelineType,
                     // conductedSurveyId: number,
                     list: ConductedSurveyReturnDto[]): Observable<void> {
    const url = `${environment.apiUrl}/conducted-survey/legacy?surveyId=${surveyId}`;
    return this.http.post<void>(url, list);
  }

  findById(conductedSurveyId: number): Observable<ConductedSurvey> {
    const url = `${environment.apiUrl}/conducted-survey/${conductedSurveyId}`;
    return this.http.get<ConductedSurvey>(url);
  }

  deleteById(id: string): Observable<void> {
    const url = `${environment.apiUrl}/conducted-survey/${id}`;
    return this.http.delete<void>(url);
  }


  deleteAll(deleteDto: ConductedSurveyDeleteDto): Observable<void> {
    const url = `${environment.apiUrl}/conducted-survey/bulk-delete`;
    return this.http.put<void>(url, deleteDto);
  }

  getByFilters(organisationId: number,
               surveyId: number,
               facilitatorId?: number,
               categoryId?: number,
               subCategoryId?: number,
               timeline?: string): Observable<ConductedSurveyScorecardDto[]> {
    let url = `${environment.apiUrl}/conducted-survey/by-filters?organisationId=${organisationId}&surveyId=${surveyId}`;
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryId) {
      url += `&subCategoryId=${subCategoryId}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }

    return this.http.get<ConductedSurveyScorecardDto[]>(url);
  }

  getByFiltersSql(organisationId: number,
                  surveyId: number,
                  facilitatorId?: number,
                  categoryId?: number,
                  subCategoryId?: number,
                  timeline?: string): Observable<ConductedSurveyScorecardDto[]> {
    //
    let url = `${environment.apiUrl}/conducted-survey/by-filters/sql?organisationId=${organisationId}&surveyId=${surveyId}`;
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryId) {
      url += `&subCategoryId=${subCategoryId}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }

    return this.http.get<ConductedSurveyScorecardDto[]>(url);
  }

  getByFiltersSqlV2(surveyIds: number[],
                    organisationId?: number,
                    facilitatorId?: number,
                    selectedCategoryFilter?: number,
                    subCategoryIdList?: number[],
                    timeline?: string,
                    dateFrom?: any,
                    dateTo?: any): Observable<ConductedSurveyScorecardDto[]> {
    //
    let url = `${environment.apiUrl}/conducted-survey/by-filters/sql/v2?surveyIds=${surveyIds}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    // if (categoryId) {
    //   url += `&categoryId=${categoryId}`;
    // }
    if (subCategoryIdList) {
      url += `&subCategoryIdList=${subCategoryIdList}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyScorecardDto[]>(url);
  }

  chartByFilters(surveyIds: number[],
                 organisationId?: number,
                 facilitatorId?: number,
                 categoryId?: number,
                 subCategoryIdList?: number[],
                 timeline?: string,
                 dateFrom?: any,
                 dateTo?: any): Observable<ConductedSurveyScorecardChartV3Dto[]> {

    if (timeline === 'All') {
      timeline = undefined;
    }
    let url = `${environment.apiUrl}/conducted-survey/by-filters/chart/v3?surveyIds=${surveyIds}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryIdList) {
      url += `&subCategoryIdList=${subCategoryIdList}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyScorecardChartV3Dto[]>(url);
  }

  defaultSurveyFilter(surveyId: number) {
    const url = `${environment.apiUrl}/conducted-survey/by-filters/question/v3?surveyId=${surveyId}`;
    return this.http.get<ConductedSurveyScorecardChartV3Dto[]>(url);
  }

  exportByFilters(surveyId: number,
                  organisationId?: number,
                  facilitatorId?: number,
                  categoryId?: number,
                  subCategoryId?: number,
                  timeline?: string,
                  dateFrom?: any,
                  dateTo?: any): Observable<ConductedSurveyScorecardExportV3Dto[]> {
    let url = `${environment.apiUrl}/conducted-survey/by-filters/export/v3?surveyId=${surveyId}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryId) {
      url += `&subCategoryId=${subCategoryId}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyScorecardExportV3Dto[]>(url);
  }

  exportByFiltersV4(surveyId: number,
                    organisationId?: number,
                    facilitatorId?: number,
                    categoryId?: number,
                    subCategoryIdList?: number[],
                    timeline?: string,
                    dateFrom?: any,
                    dateTo?: any): Observable<ConductedSurveyScorecardExportV4Dto[]> {
    let url = `${environment.apiUrl}/conducted-survey/by-filters/export/v4?surveyId=${surveyId}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryIdList) {
      url += `&subCategoryIdList=${subCategoryIdList}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyScorecardExportV4Dto[]>(url);
  }

  exportByFiltersV2(surveyIds: number[],
                    organisationId?: number,
                    facilitatorId?: number,
                    categoryId?: number,
                    subCategoryIdList?: number[],
                    timeline?: string,
                    dateFrom?: any,
                    dateTo?: any): Observable<ConductedSurveyScorecardV2Dto[]> {
    if (timeline === 'All') {
      timeline = undefined;
    }
    let url = `${environment.apiUrl}/conducted-survey/by-filters/sql/v2?surveyIds=${surveyIds}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryIdList) {
      url += `&subCategoryIdList=${subCategoryIdList}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyScorecardV2Dto[]>(url);
  }

  getByFiltersSqlGeneric(surveyId: number,
                         organisationId?: number,
                         facilitatorId?: number,
                         categoryId?: number,
                         subCategoryId?: number,
                         timeline?: string,
                         dateFrom?: any,
                         dateTo?: any): Observable<ConductedSurveyGenericDto[]> {
    //
    let url = `${environment.apiUrl}/conducted-survey/by-filters/sql/generic?surveyId=${surveyId}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    if (facilitatorId) {
      url += `&facilitatorId=${facilitatorId}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    if (subCategoryId) {
      url += `&subCategoryId=${subCategoryId}`;
    }
    if (timeline) {
      url += `&timeline=${timeline}`;
    }
    if (dateFrom) {
      url += `&dateFrom=${dateFrom}`;
    }
    if (dateTo) {
      url += `&dateTo=${dateTo}`;
    }

    return this.http.get<ConductedSurveyGenericDto[]>(url);
  }

  updateStatus(id: any, status: string) {
    const url = `${environment.apiUrl}/conducted-survey/${id}/status?status=${status}`;
    return this.http.put<ConductedSurvey>(url, undefined);
  }
}
