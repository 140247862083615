<div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <p class="text-secondary disabled">MENU</p>
    </div>

  <ul
    ngbNav
    [(activeId)]="activeId"
    class="list-group list-group-flush"
    orientation="vertical"
  >

    <li [ngbNavItem]="1">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/dashboard"
        routerLinkActive="active"
      >Dashboard</a>
    </li>

    <li [ngbNavItem]="2">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/organisation-management"
        routerLinkActive="active"
      >Organisation Management</a>
    </li>

    <li [ngbNavItem]="3">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/facilitator-management"
        routerLinkActive="active"
      >Facilitator Management</a>
    </li>

    <li [ngbNavItem]="4">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/client-management"
        routerLinkActive="active"
      >Client Management</a>
    </li>

    <li [ngbNavItem]="5">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/survey-management"
        routerLinkActive="active"
      >Survey Management</a>
    </li>

    <li [ngbNavItem]="11">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/reporting"
        routerLinkActive="active"
      >Reporting</a>
    </li>

    <li [ngbNavItem]="6">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/analytics"
        routerLinkActive="active"
      >Analytics</a>
    </li>

    <li [ngbNavItem]="7">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/notification"
        routerLinkActive="active"
      >Notifications</a>
    </li>

    <li *ngIf="roleLegacy" [ngbNavItem]="9">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/read-excel"
        routerLinkActive="active"
      >Read Excel</a>
    </li>

    <li *ngIf="roleSuperHub" [ngbNavItem]="10">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/location-management"
        routerLinkActive="active"
      >Location Management</a>
    </li>

    <li [ngbNavItem]="8">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/user"
        routerLinkActive="active"
      >User Management</a>
    </li>

    <li *ngIf="roleSuperAdmin" [ngbNavItem]="12">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/mobile-error-logs"
        routerLinkActive="active"
      >Mobile Error Logs</a>
    </li>

    <li [ngbNavItem]="13">
      <a
        ngbNavLink
        class="sidebar-link"
        routerLink="/admin/bi-dashboard-map"
        routerLinkActive="active"
      >Map of Greenlight Movement Members</a>
    </li>

<!--    <li [ngbNavItem]="14">-->
<!--      <a-->
<!--        ngbNavLink-->
<!--        class="sidebar-link"-->
<!--        routerLink="/admin/bi-dashboard"-->
<!--        routerLinkActive="active"-->
<!--      >Bi-Dashboard</a>-->
<!--    </li>-->

  </ul>
</div>
