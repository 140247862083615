import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseEntity, createBaseEntity, createBaseEntityForm} from '../../../shared/models/base-entity.model';
import {FileUploadSpaces} from '../../../shared/modules/file-upload/models/file-upload.model';
import {Province} from '../../admin/components/location-management/store/location.model';

export const PAT_EMAIL = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:\\[\x00-\x7F]|[^\\"\\\x00-\x1F\x7F])+")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:\[(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\]))$/;

export const PAT_CONTACT_NO = '^$|^[+]?[0-9]{10,14}$';

export enum SdgType {
  GOAL_1 = 'GOAL_1',
  GOAL_2 = 'GOAL_2',
  GOAL_3 = 'GOAL_3',
  GOAL_4 = 'GOAL_4',
  GOAL_5 = 'GOAL_5',
  GOAL_6 = 'GOAL_6',
  GOAL_7 = 'GOAL_7',
  GOAL_8 = 'GOAL_8',
  GOAL_9 = 'GOAL_9',
  GOAL_10 = 'GOAL_10',
  GOAL_11 = 'GOAL_11',
  GOAL_12 = 'GOAL_12',
  GOAL_13 = 'GOAL_13',
  GOAL_14 = 'GOAL_14',
  GOAL_15 = 'GOAL_15',
  GOAL_16 = 'GOAL_16',
  GOAL_17 = 'GOAL_17',
}

export enum TargetedPopulations {
  CHILDREN = 'CHILDREN',
  YOUTH = 'YOUTH',
  ADOLESCENT_GIRLS = 'ADOLESCENT_GIRLS',
  ADOLESCENT_BOYS = 'ADOLESCENT_BOYS',
  ADULTS = 'ADULTS',
  WOMEN = 'WOMEN',
  MEN = 'MEN',
  PREGNANT_WOMEN = 'PREGNANT_WOMEN',
  ELDERLY = 'ELDERLY',
  SURVIVORS = 'SURVIVORS',
  LGBTQIA = 'LGBTQIA',
  HOMELESS_PEOPLE = 'HOMELESS_PEOPLE',
  REFUGEES = 'REFUGEES',
  MIGRANT_WORKERS = 'MIGRANT_WORKERS',
  DIFFERENTLY_ABLED = 'DIFFERENTLY_ABLED',
  PRISONERS = 'PRISONERS',
  OTHER = 'OTHER'
}

export enum OrganisationServiceType {
  YOUTH = 'YOUTH',
  HIV = 'HIV',
  ECD = 'ECD',
  FOOD_SECURITY = 'FOOD_SECURITY',
  EDUCATION = 'EDUCATION',
  ADVOCACY = 'ADVOCACY',
  HEALTH = 'HEALTH',
  ARTS_AND_CULTURE = 'ARTS_AND_CULTURE',
  INFORMAL_SMALL_BUSINESS = 'INFORMAL_SMALL_BUSINESS',
  ORPHANS = 'ORPHANS',
  ENVIRONMENT = 'ENVIRONMENT',
  GBV = 'GBV',
  WOMEN_AND_GENDER = 'WOMEN_AND_GENDER',
  LGBTQIA = 'LGBTQIA',
  DISABILITIES = 'DISABILITIES',
  SMME = 'SMME',
  OTHER = 'OTHER',
}

// export enum ProvinceType {
//   GAUTENG = 'GAUTENG',
//   MPUMALANGA = 'MPUMALANGA',
//   LIMPOPO = 'LIMPOPO',
//   WESTERN_CAPE = 'WESTERN_CAPE',
//   EASTERN_CAPE = 'EASTERN_CAPE',
//   NORTHERN_CAPE = 'NORTHERN_CAPE',
//   FREE_STATE = 'FREE_STATE',
//   KWAZULU_NATAL = 'KWAZULU_NATAL',
//   NORTH_WEST = 'NORTH_WEST',
// }

export interface OrganisationProfile extends BaseEntity {
  name?: string;
  status?: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'DELETED';
  type?: 'NGO' | 'GOVERNMENT_ENTITY' | 'SOCIAL_ENTERPRISE' | 'FOR_PROFIT_BUSINESS';
  description?: string;
  hasBiReporting?: boolean;

  contact?: OrganisationContact;

  // fieldContactPerson: string;
  // fieldContactNumber: string;

  sdgs?: SdgType[];
  targetedPopulations?: TargetedPopulations[];
  services?: OrganisationServiceType[];
  // operationalProvince?: ProvinceType[];
  operationalProvinceEntities?: Province[];

  photo?: FileUploadSpaces;
}

export interface OrganisationProfileInclusiveDto extends OrganisationProfile {
  usernames: string[];
  surveyTypes: ('GREENLIGHT' | 'GENERIC') [];
  surveyNames: string[];
}

export interface NameDto {
  id: number;
  name: string;
  type: string;

}

export interface OrganisationContact extends BaseEntity {
  primaryName: string;
  // lastName: string;
  primaryEmail: string;
  primaryNumber: string;
  // landline: string;
  primaryPosition: string;

  secondaryName: string;
  secondaryNumber: string;

  streetAddress: string;
  city: string;
  // province: ProvinceType;
  provinceEntity: Province;
  postalCode: string;

  latitude: number;
  longitude: number;
}

export function createOrganisationProfile(params: Partial<OrganisationProfile>) {
  return Object.assign({}, createBaseEntity(params), {
    name: params.name,
    status: params.status,
    type: params.type,
    description: params.description,

    contact: createOrganisationContact(params.contact),

    // fieldContactPerson: params.fieldContactPerson,
    // fieldContactNumber: params.fieldContactNumber,

    services: params.services,
    // operationalProvince: params.operationalProvince,
    operationalProvinceEntities: params.operationalProvinceEntities,
    photo: params.photo,
  }) as OrganisationProfile;
}

export function createOrganisationContact(params?: Partial<OrganisationContact>) {
  return Object.assign({}, createBaseEntity(params), {
    primaryName: params && params.primaryName,
    // lastName: params && params.lastName,
    primaryEmail: params && params.primaryEmail,
    primaryNumber: params && params.primaryNumber,
    // landline: params && params.landline,
    primaryPosition: params && params.primaryPosition,

    secondaryName: params && params.secondaryName,
    secondaryNumber: params && params.secondaryNumber,

    streetAddress: params && params.streetAddress,
    city: params && params.city,
    // province: params && params.province,
    provinceEntity: params && params.provinceEntity,
    postalCode: params && params.postalCode,

    latitude: params && params.latitude,
    longitude: params && params.longitude,
  }) as OrganisationContact;
}

export function createOrganisationProfileForm(formBuilder: FormBuilder, params?: Partial<OrganisationProfile>): FormGroup {
  return formBuilder.group(Object.assign({}, createBaseEntityForm(params), {
    name: [params.name, Validators.required],
    type: [params.type, Validators.required],
    description: [params.description],
    hasBiReporting: [params.hasBiReporting || false],

    contact: formBuilder.group(Object.assign({}, createBaseEntityForm(params.contact), {
      primaryName: [params && params.contact && params.contact.primaryName || '', Validators.required],
      // lastName: [params && params.contact && params.contact.lastName || '', Validators.required],
      primaryPosition: [params && params.contact && params.contact.primaryPosition || '', Validators.required],
      primaryEmail: [params && params.contact && params.contact.primaryEmail || '', [
        Validators.required,
        Validators.pattern(PAT_EMAIL)
      ]
        // Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}')
      ],
      primaryNumber: [params && params.contact && params.contact.primaryNumber || '', [
        Validators.required,
        // Validators.pattern(PAT_CONTACT_NO)
      ]],
      // landline: [params && params.contact && params.contact.landline || ''],

      secondaryName: [params && params.contact && params.contact.secondaryName || ''],
      secondaryNumber: [params && params.contact && params.contact.secondaryNumber || '', [
        // Validators.pattern(PAT_CONTACT_NO)
      ]],


      streetAddress: [
        {value: params && params.contact && params.contact.streetAddress || '', disabled: false},
        Validators.required],
      city: [
        {value: params && params.contact && params.contact.city || '', disabled: false},
        Validators.required],
      // province: [
      //   {value: params && params.contact && params.contact.province || '', disabled: false}
      // ],
      provinceEntity: [
        {value: params && params.contact && params.contact.provinceEntity || '', disabled: false},
        Validators.required
      ],
      postalCode: [
        {value: params && params.contact && params.contact.postalCode || '', disabled: false},
        ],

      latitude: [
        {value: params && params.contact && params.contact.latitude, disabled: false}
      ],
      longitude: [
        {value: params && params.contact && params.contact.longitude, disabled: false}
      ],
    })),

    // fieldContactPerson: [params.fieldContactPerson],
    // fieldContactNumber: [params.fieldContactNumber],

    sdgs: [params.sdgs, Validators.required],
    targetedPopulations: [params.targetedPopulations, Validators.required],
    services: [params.services, Validators.required],
    // operationalProvince: [params.operationalProvince, Validators.required],
    operationalProvinceEntities: [params.operationalProvinceEntities, Validators.required],

    // photo: [params.photo, Validators.required],
    photo: formBuilder.group(Object.assign({}, createBaseEntityForm(params.photo), {
      bucket: [params && params.photo && params.photo.bucket, Validators.required],
      firstKey: [params && params.photo && params.photo.firstKey, Validators.required],
      location: [params && params.photo && params.photo.location, Validators.required],
      secondKey: [params && params.photo && params.photo.secondKey, Validators.required],
    })),

  }));
}
